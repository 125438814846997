<template>
  <div class="phonebox" style="">
    <div class="phonetitle">耗材管理</div>
    <div class="phonecx" style=" margin-top: 0px;">

      <!-- <el-date-picker class="phoneinput" style="width:90%;margin-right: 0px;" v-model="queritem" type="datetimerange" start-placeholder="开始日期" end-placeholder="结束日期">
      </el-date-picker> -->
      <el-select class="phoneinput" style="width:35%;margin-right: 10px; " v-model="status" placeholder="请选择状态：">
        <el-option label="全部" value=" ">
        </el-option>
        <el-option label="库存不足" :value="1">
        </el-option>

      </el-select>

      <el-select class="phoneinput" style="width:35%;margin-right: 10px; " v-model="type_id" placeholder="请选择类别">
        <el-option label="全部" value=" ">
        </el-option>
        <el-option v-for="item in types" :key="item" :label="item" :value="item">
        </el-option>
      </el-select>

      <el-button type="primary" @click="cx">查询</el-button>
    </div>
    <div class="phoneMain">
      <el-table :data="list" border style="width: 100%;margin-top: 20px;">
        <el-table-column prop="type" label="类别" min-width="15%"></el-table-column>
        <el-table-column prop="name" label="名称" min-width="20%"></el-table-column>
        <el-table-column prop="spec" label="规格" min-width="15%"></el-table-column>

        <el-table-column prop="safe_stock" label="安全库存" min-width="15%"></el-table-column>
        <el-table-column label="剩余库存" min-width="15%">
          <template slot-scope="scope">

            <span :style="scope.row.surplus_stock < scope.row.safe_stock?'color:red;':''">{{scope.row.surplus_stock}}</span>

          </template>

        </el-table-column>
        <el-table-column label="领用记录" min-width="20%" align="center">
          <template slot-scope="scope">
            <span class="ly" @click="linyong(scope.row)" type="warning" size="small">点击查看</span>

          </template>
        </el-table-column>

      </el-table>
      <div class="pagbox">
        <el-pagination :pager-count='5' @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="page" :page-sizes="[5,10, 50, 100]" :page-size="limit" layout="sizes, prev, pager, next" :total="total">
        </el-pagination>
      </div>

    </div>

    <div class="phoneFoot">
      <div class="footbox ">
        <router-link :to="{name:'phoneProductionOrder'}"> 生产订单</router-link>
      </div>
      <div class="footbox ">
        <router-link :to="{name:'phoneSchedule'}"> 生产排程</router-link>
      </div>
      <div class="footbox">
        <router-link :to="{name:'home'}"> 实时看板</router-link>
      </div>
      <div class="footbox">
        <router-link :to="{name:'phoneReport'}"> 设备报表</router-link>
      </div>
      <div class="footbox">
        <router-link :to="{name:'phoneStaffreport'}"> 员工报表</router-link>
      </div>
      <div class="footbox active">

        <router-link :to="{name:'phoneconsu'}"> 耗材库存</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { getConsumable } from "@/service/api";
export default {
  data () {
    return {
      list: [],
      page: 1,
      limit: 10,
      queritem: '',
      status: '',
      name: '',
      deviceList: [],
      iot_id: '',
      type_id: '',
      types: []
    };
  },
  methods: {
    xalert () {
      alert('开发中');
    },
    linyong (row) {
      console.log(row);
      this.$router.push('lylist?id=' + row.id);
    },
    swiot_id (row) {
      let text = '';

      this.deviceList.forEach(item => {
        if (item.iot_id === row.iot_id) {
          text = item.nick_name;
        }

      });
      return text;
    },
    cx () {
      this.page = 1;
      this.getlist();
    },
    handleSizeChange (val) {
      console.log(`每页 ${val} 条`);
      this.limit = val;
      this.getlist();
    },
    handleCurrentChange (val) {
      console.log(`当前页: ${val}`);
      this.page = val;
      this.getlist();
    },
    getlist () {
      let data = { limit: this.limit, page: this.page, type: this.type_id };
      getConsumable(data).then(res => {
        console.log(res);
        // res.data.list.forEach(item => {
        //   item.order_date = item.order_date ? this.dayjs(item.order_date).format('YYYY-MM-DD') : '无';
        //   item.delivery_date = item.delivery_date ? this.dayjs(item.delivery_date).format('YYYY-MM-DD') : '无';
        // });
        this.list = res.data.list;
        this.total = res.data.total;
        this.types = res.data.types;
      });
    }
  },
  mounted () {
    this.getlist();

  }
};
</script>
<style scoped>
@import './css/index.css';
</style>